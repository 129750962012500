
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {Box, Flex ,Text} from "../primitives";
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import Image from "next/image";
import Link from "next/link";
import badge from "../../public/index/badge.81bfd5d1.svg"
import HeiMdall from "../../public/index/Heimdall.webp"

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')
    let [countCard,setCountCard] = useState(0)
    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }

    return (
        <Box css={{
            width: "100%",

            backgroundColor:"#232323",
            overflow:"hidden",
            "@md":{
                height: "100vh",
            }
        }}>
            <Box css={{
                "@md":{
                    position:"relative"
                }
            }}>
                <Flex direction="column"  justify="center" css={{
                   "@md": {
                       position:"absolute",
                       left:"20%",
                       transform: "translate(0, 50%)",
                       zIndex:"5"
                   }
                }}>
                     <Flex css={{
                         marginLeft:"$4",
                         marginRight:"$4"
                     }}>
                         <Image src={badge} alt=""/>
                         <Text style="h2" css={{
                             marginLeft:"$4",

                             textAlign:"center",
                             "@md":{
                                 textAlign:"left",
                                fontSize:"48px",
                                 fontWeight:"800"
                             }
                         }}>HEIMDALL</Text>
                     </Flex>
                    <Text style="h2" css={{
                        textAlign:"center",
                        fontSize:"20px",
                        "@md":{
                            textAlign:"left",
                            fontSize:"48px",
                            fontWeight:"800"
                        }
                    }}>WELCOME TO THE</Text>
                    <Text style="h2" css={{
                        textAlign:"center",
                        fontSize:"20px",
                        "@md":{
                            textAlign:"left",
                            fontSize:"48px",
                            fontWeight:"800"
                        }
                    }}>HEIMDALL UNIVERSE (IGU)</Text>

                    <Flex align="center"  css={{
                        flexDirection:"column",
                        "@md":{
                            width:"40%",
                            flexDirection:"row",
                            margin:"$6 auto",
                            justifyContent:"end"
                        }
                    }}>
                        <Link href={`/${routePrefix}/collections/trending`} style={{
                            padding: "10px 20px",
                            margin: "10px",
                            fontSize: "1em",
                            fontFamily: "screen1",
                            color: "#ffffff",
                            backgroundColor: "green",

                            cursor: "pointer",
                            outline: "none",
                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>EXPLORE</span>
                        </Link>
                        <Link href={`/${routePrefix}/explore`} style={{
                            padding: "10px 20px",
                            marginRight: "10px",
                            fontSize: "1em",
                            fontFamily: "screen1",
                            color: "#ffffff",
                            backgroundColor: "blue",
                            marginLeft:"50px",


                            cursor: "pointer",
                            outline: "none",

                        }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>COLLECTIONS</span>
                        </Link>
                    </Flex>

                </Flex>

                <Box css={{
                    "@md":{
                        position:"absolute",
                        right:"0",
                        top:"35%"
                    }
                }}>
                    <Image src={HeiMdall} alt=""/>
                </Box>
            </Box>


        </Box>
    )
}

type TrendingCollectionsSchema =
    paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
    paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
    ssr: {
        trendingMints: TrendingMintsSchema
        trendingCollections: TrendingCollectionsSchema
        featuredCollections: TrendingCollectionsSchema
    }
}> = async ({params, res}) => {
    const chainPrefix = params?.chain || ''
    const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
// init BaseApi
// supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
// DefaultChain
// console.log("请求的路劲",reservoirBaseUrl)
    const headers: RequestInit = {
        headers: {
            'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
    }
    let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'volume',
        }

    const trendingCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    trendingCollectionsQuery,
    headers
)
let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'sales',
    }

const featuredCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    featuredCollectionQuery,
    headers
)

let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        type: 'any',
    }

const trendingMintsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending-mints/v1`,
    trendingMintsQuery,
    headers
)

const promises = await Promise.allSettled([
    trendingCollectionsPromise,
    featuredCollectionsPromise,
    trendingMintsPromise,
]).catch((e) => {
    console.error(e)
})

const trendingCollections: Props['ssr']['trendingCollections'] =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
        ? (promises[0].value.data as Props['ssr']['trendingCollections'])
        : {}
const featuredCollections: Props['ssr']['featuredCollections'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['featuredCollections'])
        : {}

const trendingMints: Props['ssr']['trendingMints'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['trendingMints'])
        : {}

res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
)
return {
    props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
}
}
export default NFTBanner